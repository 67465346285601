import { createTheme } from "@mui/material"

export const paginatorTheme = createTheme({
    components: {
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    justifyContent: 'center',
                    '& .MuiInputBase-input': {
                        border: '1px solid #BCC2C6',
                        borderRadius: '8px',
                        padding: '4px 8px 4px 12px',
                        width: '22px',
                        height: '16px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        '&:focus': {
                            borderRadius: '8px',
                            backgroundColor: 'white'
                        }
                    },
                }
            }
        }
    }
});